import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import GameComponent from './licenseplate/GameComponent'

const LicensePlate = () => {
  const [questions, setQuestions] = useState(['4812'])
  const [answer, setAnswer] = useState('')
  const [isValid, setIsValid] = useState(false)

  const currentQuestion = questions[0]

  function isValidEquation(equation, s) {
    // Split the equation at the '=' sign
    const [leftSide, rightSide] = equation.split('=')

    // First, check if the right side result is present in the string `s`
    function isRightSideValid(rightResult, s) {
      const rightResultStr = rightResult.toString()
      return s.includes(rightResultStr)
    }

    const rightResult = parseInt(rightSide)

    // If the right side is not valid, return false immediately
    if (!isRightSideValid(rightResult, s)) {
      return false
    }

    // Function to evaluate the expression on the left side
    function evaluateExpression(expression) {
      return Function(`"use strict"; return (${expression});`)()
    }

    // Evaluate the left side and compare it to the right side
    const leftResult = evaluateExpression(leftSide)
    if (leftResult !== rightResult) {
      return false
    }

    // Function to check if numbers in the left side are present in the correct order in `s`
    function areNumbersValidAndOrdered(expression, s) {
      const numbers = expression.split(/[*+-/]/).map((num) => num.trim()) // Split by operators and trim spaces
      let currentIndex = 0

      for (let number of numbers) {
        currentIndex = s.indexOf(number, currentIndex) // Check if number is found after the last found index
        if (currentIndex === -1) {
          return false // Number is not in the correct order or not present
        }
        currentIndex += number.length // Move to the next part of the string
      }

      return true
    }

    // Finally, check if the numbers on the left side are valid and ordered
    if (!areNumbersValidAndOrdered(leftSide, s)) {
      return false
    }

    return true
  }

  const handleCheck = (e) => {
    e.preventDefault()
    const result = isValidEquation(answer, currentQuestion)
    setIsValid(result)
  }

  return <GameComponent />

  return (
    <div>
      Question : {currentQuestion}
      <Form onSubmit={handleCheck}>
        <Form.Group className='d-flex align-items-center gap-2'>
          <Form.Label htmlFor='answer' className='fs-1 mb-0'>
            Answer
          </Form.Label>
          <Form.Control
            type='text'
            className='border border-2 rounded-3'
            id='answer'
            name='answer'
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            required
            autoFocus
            isValid={isValid}
          />
        </Form.Group>
        <Form.Control.Feedback>Please enter required field</Form.Control.Feedback>
        <button type='submit'>Check</button>
      </Form>
    </div>
  )
}

export default LicensePlate

function generateHiddenCellsSimple() {
  const rows = 52 // Total number of rows
  const columns = 3
  const hideCount = 7
  const totalHiddenCells = hideCount * columns

  const randomRows = new Set()

  // Generate unique random row indices
  while (randomRows.size < totalHiddenCells) {
    const row = Math.floor(Math.random() * rows)
    randomRows.add(row)
  }

  // Convert Set to Array and split into columns
  const hiddenRowsArray = Array.from(randomRows)
  const hiddenCells = []
  for (let col = 0; col < columns; col++) {
    hiddenCells.push(hiddenRowsArray.slice(col * hideCount, (col + 1) * hideCount))
  }

  return hiddenCells
}

// Generate and log the hidden cells
const hiddenCells = generateHiddenCellsSimple()
console.log('Hidden cells by column:')
hiddenCells.forEach((cells, colIndex) => {
  console.log(`Column ${colIndex + 1}:`, cells)
})
