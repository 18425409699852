import React from 'react'
import { Outlet } from 'react-router-dom'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'

const RecommendationLayout = () => {
  return (
    <div className='content flex-grow-1 text-black mx-3'>
      <div className='dashboard-heading'>
        <div className='content-container'>
          <h2>Recommentation</h2>
          <p>Find recommendations based on your strengths and weakness</p>
        </div>
        <Activity />
      </div>
      <Header />

      <div className='dashboard-cards rounded-0 p-0'>
        <Outlet />
      </div>
    </div>
  )
}

export default RecommendationLayout
