import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { format, subMonths } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { Container, FloatingLabel, Form } from 'react-bootstrap'
import { Bar, Doughnut } from 'react-chartjs-2'
import * as API from '../Apisurl'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import Context from '../Context'
import ReactApexChart from 'react-apexcharts'

ChartJS.register(ArcElement, Tooltip, PointElement, LineElement, Legend, CategoryScale, LinearScale, BarElement, Title)

const Overview = () => {
  const isGroup = localStorage.getItem('isGroup')
  const context = useContext(Context)
  const { selectValue } = context

  const [lp, setLp] = useState()
  const [ms, setMs] = useState()
  const [brainStrength, setBrainStrength] = useState()
  const [userAnalysis, setUserAnalysis] = useState()

  const [specialities, setSpecialities] = useState([])

  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(true)

  const [filter, setFilter] = useState({
    gender: 'all',
    startDate: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  })

  useEffect(() => {
    const getSpecialities = async () => {
      setLoading1(true)
      try {
        const response = await fetch(`${API.Fetchurl}get_all_speciality`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
        })

        const data = await response.json()
        setSpecialities(data.map((d) => d.toLowerCase()))
        setLoading1(false)
      } catch (error) {
        console.log(error)
      }
    }
    getSpecialities()
  }, [])

  useEffect(() => {
    const getinfo = async () => {
      setLoading(true)

      let sdate = filter.startDate.split('-')
      let edate = filter.endDate.split('-')

      let s_y = sdate[0],
        s_m = sdate[1]
      let e_y = edate[0],
        e_m = edate[1]

      try {
        const response = await fetch(
          `${API.Fetchurl}overview_analysis?isGroup=${isGroup}&id=${selectValue.user_id}&is_gender=${filter.gender}&start_year=${s_y}&start_month=${s_m}&end_year=${e_y}&end_month=${e_m}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('session')}`,
            },
          }
        )

        if (!response.ok) throw new Error('Something Went Wrong...')

        const data = await response.json()

        setMs({ Stress: data.average.difference.stress, Attention: data.average.difference.attention })
        setLp({
          Interactive: data.learning.all.interactive,
          Audio: data.learning.all.audio,
          Visual: data.learning.all.vidoes,
        })

        let obj = {}
        Object.entries(data.average).forEach(([key, value]) => {
          if (specialities.includes(key.toLowerCase())) {
            obj[key] = value
          }
        })

        setBrainStrength(obj)

        setUserAnalysis([
          data.attempted_analysis.gender_dis.all,
          data.attempted_analysis.gender_dis.M,
          data.attempted_analysis.gender_dis.F,
          data.attempted_analysis.gender_dis.unattempt,
        ])

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    !loading1 && getinfo()
  }, [selectValue.user_id, filter.gender, filter.startDate, filter.endDate, loading1])

  const doughnutLabel = {
    id: 'doughnutLabel',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart
      ctx.save()

      const xCoor = chart.getDatasetMeta(0).data[0].x
      const yCoor = chart.getDatasetMeta(0).data[0].y
      ctx.font = 'bold 30px sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${Math.abs(data.datasets[0].data[0])}%`, xCoor, yCoor)
    },
  }

  return (
    <div className='content flex-grow-1 text-black'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Hi there</h2>
            <p>Here’s a quick overview for your reports</p>
          </div>
          <Activity />
        </div>
        {isGroup === 'true' && <Header />}
        <div className='dashboard-cards d-flex flex-column gap-4'>
          <div className='d-flex flex-wrap align-items-center gap-3'>
            <FloatingLabel controlId='gender' label='Gender'>
              <Form.Select
                required
                type='select'
                value={filter.gender}
                onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
                className='text-capitalize'
              >
                {['all', 'male', 'female'].map((gender, i) => (
                  <option key={i} value={gender}>
                    {gender.replaceAll('_', ' ')}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId='start' label='Start Date'>
              <Form.Control
                required
                type='date'
                value={filter.startDate}
                onChange={(e) => setFilter({ ...filter, startDate: e.target.value })}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId='end' label='End Date'>
              <Form.Control
                required
                type='date'
                value={filter.endDate}
                onChange={(e) => setFilter({ ...filter, endDate: e.target.value })}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
          </div>

          {!loading && (
            <>
              {/* --------------------- Row 1 --------------------------------------------  */}
              <div className='d-flex flex-wrap gap-4 justify-content-around align-items-center'>
                <div>
                  <div
                    className='rounded-pill px-3 py-2'
                    style={{ backgroundColor: 'var(--primary-color)', margin: '12px auto', width: 'fit-content' }}
                  >
                    <h4 className='m-0 text-white text-center'>Learning Patterns</h4>
                  </div>

                  <div className='d-flex flex-wrap gap-4'>
                    {Object.entries(lp)
                      .reverse()
                      .map(([k, v], i) => {
                        let info = {
                          Visual: 'Video-audio based content',
                          Audio: 'Traditional audio classroom setup',
                          Interactive: 'Gamified content touch and feel',
                        }

                        let data = getDonutData(v)
                        // let series = [v]
                        // let options = {
                        //   plotOptions: {
                        //     radialBar: {
                        //       hollow: {
                        //         size: '40%',
                        //         dropShadow: {
                        //           enabled: true,
                        //           top: 5,
                        //           left: 0,
                        //           blur: 38,
                        //           opacity: 0.8
                        //         }
                        //       },

                        //       dataLabels: {
                        //         name: {
                        //           show: false,
                        //         },
                        //         value: {
                        //           show: true,
                        //           fontSize: '30px',
                        //           fontWeight: 'bold',
                        //           fontFamily: "sans-serif",
                        //           offsetY: 10,

                        //           formatter: (val) => val + '%'
                        //         },
                        //       }
                        //     },
                        //   },
                        //   colors: ['#15a48f',],
                        //   labels: [k],
                        //   stroke: {
                        //     lineCap: 'round'
                        //   },

                        // }

                        return (
                          <div key={i} className='mx-auto'>
                            <h4
                              className='text-capitalize text-center opacity-100 text-black m-0 my-2'
                              style={{ letterSpacing: 'revert' }}
                            >
                              {k}
                            </h4>
                            <div>
                              {/* <ReactApexChart options={options} series={series} type="radialBar" height={275} /> */}

                              <Doughnut
                                data={data}
                                height='150px'
                                width='150px'
                                plugins={[doughnutLabel]}
                                options={{
                                  maintainAspectRatio: false,
                                  plugins: {
                                    tooltip: {
                                      enabled: false,
                                    },
                                  },
                                }}
                              />
                            </div>
                            <p className='m-0 my-2'>{info[k]}</p>
                          </div>
                        )
                      })}
                  </div>
                </div>
                <div>
                  <div
                    className='rounded-pill px-3 py-2'
                    style={{ backgroundColor: 'var(--primary-color)', margin: '12px auto', width: 'fit-content' }}
                  >
                    <h4 className='m-0 text-white text-center'>Mental State</h4>
                  </div>

                  <div className='d-flex flex-wrap gap-4'>
                    {Object.entries(ms).map(([k, v], i) => {
                      let color
                      if (v >= 0) {
                        color = k === 'Stress' ? '#be5f5a' : '#15a48f'
                      } else {
                        color = k === 'Stress' ? '#15a48f' : '#be5f5a'
                      }
                      return (
                        <div key={i} className='mx-auto'>
                          <h4
                            className='text-capitalize text-center opacity-100 text-black m-0 my-2'
                            style={{ letterSpacing: 'revert' }}
                          >
                            {k}
                          </h4>
                          <div>
                            <Doughnut
                              data={{
                                datasets: [
                                  {
                                    data: [v, 100 - v],
                                    backgroundColor: [color, '#D9D9D9'],
                                    borderRadius: 4,
                                    borderWidth: 0,
                                    cutout: '60%',
                                  },
                                ],
                              }}
                              height='150px'
                              width='150px'
                              plugins={[doughnutLabel]}
                              options={{
                                maintainAspectRatio: false,
                                plugins: {
                                  tooltip: {
                                    enabled: false,
                                  },
                                },
                              }}
                            />
                          </div>
                          <p className='m-0 my-2'>
                            {k} {v >= 0 ? 'increased' : 'decreased'} by {Math.abs(v)}%
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {/* --------------------- Row 2 --------------------------------------------  */}
              <div className='d-flex flex-wrap justify-content-around align-items-center'>
                <div style={{ width: '55%' }}>
                  <div
                    className='rounded-pill px-3 py-2'
                    style={{ backgroundColor: 'var(--primary-color)', margin: '12px auto', width: 'fit-content' }}
                  >
                    <h4 className='m-0 text-white text-center'>Brain Strengths</h4>
                  </div>

                  <Bar data={getBarData(brainStrength)} options={options} height='120px' />
                </div>
                <div style={{ width: '35%' }}>
                  <div
                    className='rounded-pill px-3 py-2'
                    style={{ backgroundColor: 'var(--primary-color)', margin: '12px auto', width: 'fit-content' }}
                  >
                    <h4 className='m-0 text-white text-center'>User Interactions</h4>
                  </div>

                  <Bar
                    data={{
                      labels: ['All', 'Male', 'Female', 'Unattempted'],
                      datasets: [
                        {
                          data: userAnalysis,
                          backgroundColor: '#7dc9bd',
                          borderRadius: 8,
                          minBarLength: 2,
                          maxBarThickness: 50,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        tooltip: {
                          displayColors: false,
                        },
                        legend: {
                          display: false,
                        },
                      },

                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          min: 0,
                          ticks: {
                            stepSize: 100,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Overview

export const getColor = (val) => {
  return val <= 30 ? '#be5f5a' : val <= 60 ? '#BDA25C' : '#15a48f'
}

export const getDonutData = (value) => {
  let color = '#15a48f'

  return {
    datasets: [
      {
        data: [Math.round(value), 100 - value],
        backgroundColor: [color, '#D9D9D9'],
        borderRadius: 4,
        borderWidth: 0,
        cutout: '60%',
      },
    ],
  }
}

export const getBarData = (data) => {
  let labels = Object.keys(data).map((l) => l.charAt(0).toUpperCase() + l.slice(1))
  let d = Object.values(data).map(({ association, interest, focus, memory }) => {
    return [association, interest, focus, memory]
  })

  return {
    labels: labels,
    datasets: [
      {
        label: 'Association',
        data: d.map((val) => val[0]),
        backgroundColor: '#2b8bba',
        borderRadius: 8,
        minBarLength: 2,
        maxBarThickness: 50,
      },
      {
        label: 'Interactive',
        data: d.map((val) => val[1]),
        backgroundColor: '#40b9d6',
        borderRadius: 8,
        minBarLength: 2,
        maxBarThickness: 50,
      },
      {
        label: 'Focus',
        data: d.map((val) => val[2]),
        backgroundColor: '#7dc9bd',
        borderRadius: 8,
        minBarLength: 2,
        maxBarThickness: 50,
      },
      {
        label: 'Memory',
        data: d.map((val) => val[3]),
        backgroundColor: '#19a393',
        borderRadius: 8,
        minBarLength: 2,
        maxBarThickness: 50,
      },
    ],
  }
}

export const options = {
  plugins: {
    tooltip: {
      displayColors: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 25,
        callback: (value) => {
          return value + '%'
        },
      },
    },
  },
}
