import React from 'react'
import { useState, useEffect, useRef, useContext } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import QAcharts from '../dashboard/QAcharts'

import ReportTable from './ReportTable'
import Style from '../cssmodules/button.module.css'
import Showresult from '../dashboard/Showresult'
import Activity from '../dashboard/Activity'
import { Form } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Pm from '../dashboard/Pm'
import EmotionalAnalysis from '../dashboard/EmotionalModal'

import * as Apiurl from '../Apisurl'
import moment from 'moment'
import Context from '../Context'
import Bloom from '../dashboard/Chart1'
import { AllReportsTable } from './Allreport'

const SchoolReport = (props) => {
  const [reportdata, setreportdata] = useState()
  const [sortByDate, setSortByDate] = useState(1)
  const [allData, setallData] = useState([])
  const selectData = useContext(Context)
  const [via, setvia] = useState(false)
  const [lgShow, setLgShow] = useState(false)

  function download(e) {
    let downloadbtn = e.target.value
    async function downloadexcel() {
      const response = await fetch(`${Apiurl.Fetchurl + 'download'}`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: sessionStorage.getItem('session'),
          type: downloadbtn,
        }),
      })
      const data = await response.json()
      return data
    }
    downloadexcel()
      .then((data) => {})
      .catch((error) => {
        console.log(error)
      })
  }

  const filterDate = (startDate, endDate, month, e, fromDate, item, months) => {
    let filldata
    switch (e) {
      case 1:
        console.log(allData)
        filldata = allData
        break
      case 2:
        filldata = moment(fromDate).isBetween(startDate, endDate) ? item : ''
        break
      case 3:
        filldata = month == months ? item : ''
        break
    }

    return filldata
  }

  const sortDate = (res) => {
    var startDate = moment()
      .startOf('week')
      .format('DD/MM/YYYY ')
    var endDate = moment()
      .endOf('week')
      .format('DD/MM/YYYY')
    let month = moment().month() + 1
    let d = allData.filter((item, index) => {
      let datei = item.date.split(':')[0]
      let months = 1 + moment(datei, 'YYYY/MM/DD').month()
      let fromDate = moment(datei).format('DD/MM/YYYY')
      return filterDate(startDate, endDate, month, res, fromDate, item, months)
    })
    setSortByDate(res)
    setreportdata(d)
  }

  return (
    <>
      <div className='content flex-grow-1'>
        <Container fluid className='option-main-container report-tab'>
          <div className='dashboard-heading'>
            <div class='content-container'>
              <h2>Reports</h2>
              <p>Check out you cumulative and test-wise reports here</p>
            </div>
            <Activity />
          </div>
          <div className='d-print-none'>
            <Showresult />
          </div>
          <Tabs defaultActiveKey='profile' id='uncontrolled-tab-example' className='mb-3'>
            <Tab eventKey='home' title='Cumulative Reports'>
              <div className='allreport '>
                <AllReportsTable userId={selectData.selectValue.user_id} />
              </div>
            </Tab>
            <Tab eventKey='profile' title='Test-Wise Reports'>
              <div className='week-report'>
                <button
                  type='button'
                  className={sortByDate == 1 ? 'btn btn-all ' : 'btn'}
                  onClick={() => sortDate(1, 'All')}
                >
                  All
                </button>
                <button
                  type='button'
                  className={sortByDate == 2 ? 'btn btn-all ' : 'btn btn-grey'}
                  onClick={() => sortDate(2, 'This Week')}
                >
                  This Week
                </button>
                <button
                  type='button'
                  className={sortByDate == 3 ? 'btn btn-all ' : 'btn btn-grey'}
                  onClick={() => sortDate(3, 'This Month')}
                >
                  This Month
                </button>
                <Form.Select aria-label='Default select example' className='download-drop' onChange={download}>
                  <option>Download here</option>
                  <option value='csv'>CSV</option>
                  <option value='excel'>Excel</option>
                </Form.Select>
              </div>
              <ReportTable reportdata={selectData.reports_table} />
            </Tab>
          </Tabs>
        </Container>

        <EmotionalAnalysis via={lgShow} setvia={setLgShow} />

        <Modal size='lg' show={via} onHide={() => setvia(false)}>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <img src={require('../assets/images/blooimg.jpeg')} style={{ width: '100%' }} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SchoolReport
