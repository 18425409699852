import React, { useEffect, useState, useMemo } from 'react'
import HeaderPdf from './pdfparts/header'
import { Col, Row } from 'react-bootstrap'

const Recommendations = ({ profileData }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [recommendations, setRecommendations] = useState(null)

  const progressData = useMemo(
    () => [
      {
        title: 'Reasoning',
        percentage: profileData.charts.Brain_Strength.thinking[0],
        optimal: profileData.charts.Brain_Strength.thinking[2],
      },
      {
        title: 'Memory',
        percentage: profileData.charts.Brain_Strength.memory[0],
        optimal: profileData.charts.Brain_Strength.memory[2],
      },
      {
        title: 'Association',
        percentage: profileData.charts.Brain_Strength.association[0],
        optimal: profileData.charts.Brain_Strength.association[2],
      },
      {
        title: 'Visualization',
        percentage: profileData.charts.Brain_Strength.vision[0],
        optimal: profileData.charts.Brain_Strength.vision[2],
      },
    ],
    [profileData]
  )

  useEffect(() => {
    const getActivities = async (category, zone) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}get_activities?category=${category}&zone=${zone}`)
        const { data } = await res.json()
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    }

    const getZone = (val, optimal) => {
      if (val >= optimal) return 'green'
      else if (val >= 40) return 'yellow'
      return 'red'
    }

    const getCategoriesAndZones = () => {
      return progressData
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 2)
        .map((item) => ({
          category: item.title.toLowerCase(),
          zone: getZone(item.percentage, item.optimal),
        }))
    }

    const fetchActivities = async (categoriesAndZones) => {
      const recommendations = {}

      for (const { category, zone } of categoriesAndZones) {
        try {
          const activity = await getActivities(category, zone)
          recommendations[category] = activity
        } catch (error) {
          console.error(error)
        }
      }

      setRecommendations(recommendations)
    }

    const categoriesAndZones = getCategoriesAndZones()
    if (categoriesAndZones.length > 0) {
      fetchActivities(categoriesAndZones)
    }
  }, [progressData])

  if (!recommendations || Object.keys(recommendations).length === 0) {
    return <div>Loading recommendations...</div>
  }

  return (
    <div className='main-container page9-container'>
      <HeaderPdf />
      <div className='pdf-content-container'>
        <div className='heading-seprator'>
          <h1>
            <span>ACTIVITIES RECOMMENDATION</span>
          </h1>
          <div className='report-content'>
            <p>
              Based on your report, we’ve picked some activities that can help you think faster, remember better, and stay focused. These activities
              are designed to improve specific areas of your brain therefore improving your overall cognitive ability.
            </p>

            <div className='listing-container'>
              <div className='d-flex align-items-center gap-2 border-bottom d-print-none'>
                {Object.keys(recommendations).map((category, i) => (
                  <button
                    key={i}
                    onClick={() => setActiveTab(i)}
                    className='px-4 py-1 text-capitalize'
                    style={{
                      border: '0px',
                      borderTopRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      color: activeTab === i ? 'white' : 'black',
                      background: activeTab === i ? 'var(--button-color)' : '#e6e6e6',
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <Row className='d-print-none'>
                {Object.values(recommendations)[activeTab] && (
                  <Col className='p-1' xs={12}>
                    <RecommendationItem activity={Object.values(recommendations)[activeTab]} />
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recommendations

const RecommendationItem = ({ activity }) => {
  return (
    <div className='shadow-sm h-100 overflow-hidden' style={{ borderRadius: '12px' }}>
      <div className='d-flex align-items-center justify-content-between border-bottom fw-bold px-2 py-1 mb-0'>
        <span>
          {activity.zone === 'green' ? '🟢' : activity.zone === 'yellow' ? '🟡' : '🔴'} {activity.name}
        </span>
        <span style={{ fontSize: '12px' }}>⏱️{activity.time}m</span>
      </div>
      <ul className='p-3'>
        <li className='mb-3'>
          🎯
          <div className='list-item ms-2'>
            <h3 className='mb-0'>Objective</h3>
            <p>{activity.objective}</p>
          </div>
        </li>
        <li className='mb-3'>
          📦
          <div className='list-item ms-2'>
            <h3 className='mb-0'>Materials</h3>
            <p>{activity.materials}</p>
          </div>
        </li>
        {/* <li className='mb-3'>
          📝
          <div className='list-item ms-2'>
            <h3 className='mb-0'>Instructions</h3>
            {activity.instructions.map((step, index) => (
              <p key={index}>
                {index + 1}.{step}
              </p>
            ))}
          </div>
        </li> */}
        <li className='mb-3'>
          🎉
          <div className='list-item ms-2'>
            <h3 className='mb-0'>Outcome</h3>
            <p>{activity.outcome}</p>
          </div>
        </li>
      </ul>
    </div>
  )
}

// <Row className='d-none d-print'>
// <div className='d-flex align-items-center gap-2 border-bottom'>
//   {Object.keys(recommendations).map((category, i) => (
//     <button
//       key={i}
//       className='px-4 py-1 text-capitalize'
//       style={{
//         border: '0px',
//         borderTopRightRadius: '12px',
//         borderTopLeftRadius: '12px',
//         color: 0 === i ? 'white' : 'black',
//         background: 0 === i ? 'var(--button-color)' : '#e6e6e6',
//       }}
//     >
//       {category}
//     </button>
//   ))}
// </div>
// {Object.values(recommendations)[0].map((activity, i) => (
//   <Col className='p-1' xs={12}>
//     <div key={i} className='shadow-sm h-100 overflow-hidden' style={{ borderRadius: '12px' }}>
//       <div className='d-flex align-items-center justify-content-between border-bottom fw-bold  px-2 py-1 mb-0'>
//         <span>
//           {activity.zone === 'green' ? '🟢' : activity.zone === 'yellow' ? '🟡' : '🔴'} {activity.name}
//         </span>
//         <span style={{ fontSize: '12px' }}>⏱️{activity.time}m</span>
//       </div>
//       <ul className='p-3'>
//         <li className='mb-3'>
//           🎯
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Objective</h3> <p>{activity.objective}</p>
//           </div>
//         </li>
//         <li className='mb-3'>
//           📦
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Materials</h3> <p>{activity.materials}</p>
//           </div>
//         </li>
//         <li className='mb-3'>
//           📝
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Instructions</h3>

//             {activity.instructions.map((step, index) => (
//               <p key={index}>
//                 {index + 1}.{step}
//               </p>
//             ))}
//           </div>
//         </li>
//         <li className='mb-3'>
//           🎉
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Outcome</h3> <p>{activity.outcome}</p>
//           </div>
//         </li>
//       </ul>
//     </div>
//   </Col>
// ))}
// </Row>
// <Row className='d-none d-print page-break'>
// <div className='d-flex align-items-center gap-2 border-bottom'>
//   {Object.keys(recommendations).map((category, i) => (
//     <button
//       key={i}
//       className='px-4 py-1 text-capitalize'
//       style={{
//         border: '0px',
//         borderTopRightRadius: '12px',
//         borderTopLeftRadius: '12px',
//         color: 1 === i ? 'white' : 'black',
//         background: 1 === i ? 'var(--button-color)' : '#e6e6e6',
//       }}
//     >
//       {category}
//     </button>
//   ))}
// </div>
// {Object.values(recommendations)[1].map((activity, i) => (
//   <Col className='p-1' xs={12}>
//     <div key={i} className='shadow-sm h-100 overflow-hidden' style={{ borderRadius: '12px' }}>
//       <div className='d-flex align-items-center justify-content-between border-bottom fw-bold  px-2 py-1 mb-0'>
//         <span>
//           {activity.zone === 'green' ? '🟢' : activity.zone === 'yellow' ? '🟡' : '🔴'} {activity.name}
//         </span>
//         <span style={{ fontSize: '12px' }}>⏱️{activity.time}m</span>
//       </div>
//       <ul className='p-3'>
//         <li className='mb-3'>
//           🎯
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Objective</h3> <p>{activity.objective}</p>
//           </div>
//         </li>
//         <li className='mb-3'>
//           📦
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Materials</h3> <p>{activity.materials}</p>
//           </div>
//         </li>
//         <li className='mb-3'>
//           📝
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Instructions</h3>

//             {activity.instructions.map((step, index) => (
//               <p key={index}>
//                 {index + 1}.{step}
//               </p>
//             ))}
//           </div>
//         </li>
//         <li className='mb-3'>
//           🎉
//           <div className='list-item ms-2'>
//             <h3 className='mb-0'>Outcome</h3> <p>{activity.outcome}</p>
//           </div>
//         </li>
//       </ul>
//     </div>
//   </Col>
// ))}
// </Row>
