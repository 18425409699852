import React from 'react'
import { Form, Button, InputGroup } from 'react-bootstrap'
import styles from './EquationInput.module.css'

const EquationInput = ({ value, onChange, onSubmit }) => {
  return (
    <InputGroup className='mb-3'>
      <Form.Control
        className={styles.input}
        type='text'
        placeholder='Enter equation'
        value={value}
        onChange={onChange}
      />
      <Button variant='success' onClick={onSubmit}>
        Submit
      </Button>
    </InputGroup>
  )
}

export default EquationInput
