import React, { useContext, useEffect, useState } from 'react'
import * as Apiurl from '../Apisurl'
import { Col, Container, Row } from 'react-bootstrap'
import Activity from '../dashboard/Activity'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import Context from '../Context'
import CustomPagination from '../includes/CustomPagination'

const Aggregate = () => {
  const { selectValue } = useContext(Context)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [sortBy, setSortBy] = useState()
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 12 // Number of items per page

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}get_all_category`, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('session'),
          },
        })
        const data = await response.json()
        const modifiedData = data.map((d) => d.toLowerCase())
        setCategories(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getCategories()
  }, [])

  useEffect(() => {
    let isGroup = localStorage.getItem('isGroup') === 'true'

    const getCharts = async () => {
      try {
        setLoading(true)
        const response = await fetch(`${Apiurl.Fetchurl + 'brain_scan_aggregate_all'}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('session'),
          },
          body: JSON.stringify({ isGroup, user_id: selectValue.user_id }),
        })

        const res = await response.json()
        let chartData = Object.entries(res).map(([k, d]) => {
          return { name: k, chart: d.aggregate }
        })

        chartData.sort((a, b) => {
          let x = Object.values(a.chart)
            // .filter((v) => v > 0)
            .reduce((prev, curr, i, arr) => {
              prev += curr
              if (i === arr.length - 1) return prev / arr.length
              else return prev
            }, 0)

          let y = Object.values(b.chart)
            // .filter((v) => v > 0)
            .reduce((prev, curr, i, arr) => {
              prev += curr
              if (i === arr.length - 1) return prev / arr.length
              else return prev
            }, 0)

          return y - x
        })

        setData(chartData)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    getCharts()
  }, [selectValue.user_id])

  data &&
    data.sort((a, b) => {
      if (a.chart[sortBy] === undefined && b.chart[sortBy] === undefined) return 0
      else if (a.chart[sortBy] === undefined) return 1
      else if (b.chart[sortBy] === undefined) return -1
      else return b.chart[sortBy] - a.chart[sortBy]
    })

  const reset = () => {
    setSortBy('')
    data.sort((a, b) => {
      let x = Object.values(a.chart)
        // .filter((v) => v > 0)
        .reduce((prev, curr, i, arr) => {
          prev += curr
          if (i === arr.length - 1) return prev / arr.length
          else return prev
        }, 0)

      let y = Object.values(b.chart)
        // .filter((v) => v > 0)
        .reduce((prev, curr, i, arr) => {
          prev += curr
          if (i === arr.length - 1) return prev / arr.length
          else return prev
        }, 0)

      return y - x
    })
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const handleSearchQueryChange = (e) => {
    setSearch(e.target.value)
    setCurrentPage(1)
  }

  const chart = search ? data?.filter((d) => d.name.toLowerCase().includes(search.toLowerCase())) : data

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='option-main-container report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Aggregate</h2>
            <p>Aggregate data of all</p>
          </div>
          <Activity />
        </div>

        <div className='d-flex align-items-center justify-content-between'>
          <div className='chips-container mt-4'>
            <span>Sort By</span>

            <ul className='chips-ul'>
              <button className='common-btn px-4 py-2' onClick={reset}>
                Reset
              </button>
              {categories?.map((c, i) => {
                return (
                  <li key={i} className={sortBy === c ? 'active' : ''} onClick={() => setSortBy(c)}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      className='feather feather-check'
                    >
                      <path d='M20 6 9 17l-5-5' />
                    </svg>
                    {c}
                  </li>
                )
              })}
            </ul>
          </div>
          <div>
            <input type='text' placeholder='Search' value={search} onChange={handleSearchQueryChange} />
          </div>
        </div>

        {!loading && (
          <Row>
            {chart.length > 0 ? (
              chart.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map(({ name, chart }, i) => {
                return (
                  <Col xl={4} key={i}>
                    <div className='dashboard-cards stat-container'>
                      <div class='performance-heading'>
                        <div class='performance-content'>
                          <h4 className='text-black'>{name}</h4>
                        </div>
                      </div>
                      <Bar
                        plugins={[ChartDataLabels]}
                        data={{
                          labels: Object.keys(chart).map((k) => k.charAt(0).toUpperCase() + k.slice(1)),
                          datasets: [
                            {
                              data: Object.values(chart),
                              backgroundColor: bgColors,
                              barPercentage: 0.5,
                              barThickness: 40,
                              maxBarThickness: 50,
                              borderRadius: 8,
                              minBarLength: 2,
                            },
                          ],
                        }}
                        options={options}
                      />
                    </div>
                  </Col>
                )
              })
            ) : (
              <div className='dashboard-cards'>
                <h2>No Results</h2>
              </div>
            )}
            <CustomPagination
              totalItems={chart.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Row>
        )}
      </Container>
    </div>
  )
}

export default Aggregate

export const bgColors = ['#c8d1fc', '#b3ddf6', '#95d8a5', '#c5e49e', '#fedf6b', '#eea08c', '#B7A1C7']

export const options = {
  layout: {
    padding: {
      top: 30,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          anchor: 'end',
          align: 'top',
          display: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 16,
          },
          offset: 0,
          formatter: (value, ctx) => {
            return value
          },
        },
      },
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      min: 0,
      max: 100,
    },
  },
}
