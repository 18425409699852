import React, { useEffect, useState } from 'react'
import { Col, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap'
import { HiCheckCircle, HiChevronRight } from 'react-icons/hi'
import { NavLink } from 'react-router-dom'

const RecommendationSystem = () => {
  const [selected, setSelected] = useState('Memory')
  const [activities, setActivities] = useState(data)

  if (!activities) return

  const result = activities[selected].reduce(
    (acc, activity) => {
      acc.total += 1

      acc[activity.zone.toLowerCase()].total += 1

      if (activity.isCompleted) {
        acc.completed += 1
        acc[activity.zone.toLowerCase()].completed += 1
      }

      return acc
    },
    {
      total: 0,
      completed: 0,
      green: { completed: 0, total: 0 },
      yellow: { completed: 0, total: 0 },
      red: { completed: 0, total: 0 },
    }
  )

  const { total, completed, green, yellow, red } = result

  const handleCompleted = (activityName) => {
    setActivities((oldActivities) => {
      // Ensure selected activity exists
      if (!oldActivities[selected]) {
        console.error(`Activity with name "${activityName}" not found in category "${selected}"`)
        return oldActivities // Prevent potential errors, return unmodified data
      }

      const updatedActivities = { ...oldActivities }
      const activityIndex = updatedActivities[selected].findIndex((activity) => activity.name === activityName)

      // Handle activity not found within the selected category
      if (activityIndex === -1) {
        console.error(`Activity with name "${activityName}" not found in category "${selected}"`)
        return updatedActivities // Prevent potential errors, return unmodified data
      }

      // Update activity completion status
      updatedActivities[selected][activityIndex].isCompleted = !updatedActivities[selected][activityIndex].isCompleted

      return updatedActivities
    })
  }

  return (
    <>
      <Row className='m-0 p-0 mb-3'>
        {Object.keys(activities)?.map((category) => {
          return (
            <Col className='p-0'>
              <button
                onClick={() => setSelected(category)}
                className={`border-0 border-3 border-bottom ${
                  selected.toLowerCase() === category.toLowerCase() ? 'border-success' : ' bg-transparent'
                } w-100 text-capitalize py-3 px-4`}
              >
                {category}
              </button>
            </Col>
          )
        })}
      </Row>
      <Row className='m-0'>
        <Col xl={8} className='d-flex flex-column gap-4 p-3'>
          {activities[selected]?.map((activity) => {
            return (
              <div className='bg-light py-2 px-3 rounded-3 d-flex justify-content-between align-items-center'>
                <div>
                  <div className='d-flex align-items-center gap-2'>
                    {activity.zone === 'Green' ? '🟢' : activity.zone === 'Yellow' ? '🟡' : '🔴'}
                    <OverlayTrigger placement='auto' delay={{ show: 150, hide: 200 }} overlay={<Tooltip>View more detail</Tooltip>}>
                      <NavLink to={`${activity.name.toLowerCase().replace(' ', '-')}`} state={{ activity }} className={`text-black`}>
                        <span className=''>
                          {activity.name}
                          <HiChevronRight size={20} fill='black' />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </div>
                  <span style={{ fontSize: 14 }}>{activity.time} mins</span>
                </div>

                <OverlayTrigger placement='auto' delay={{ show: 150, hide: 200 }} overlay={<Tooltip>Mark as Completed</Tooltip>}>
                  <button className='border-0 bg-transparent p-0 m-0' onClick={() => handleCompleted(activity.name)}>
                    <HiCheckCircle size={24} fill={activity.isCompleted ? 'green' : '#c4c4c4'} />
                  </button>
                </OverlayTrigger>
              </div>
            )
          })}
        </Col>
        <Col xl={4} className='p-3'>
          <p>Overall Progress</p>
          <div
            className='rounded-circle bg-light mx-auto mb-4 d-flex align-items-center justify-content-center'
            style={{
              height: 200,
              width: 200,
              background: `conic-gradient(skyblue ${(completed / total) * 360}deg, #e9e9e9 0deg)`,
            }}
          >
            <div
              className='rounded-circle mx-auto shadow-sm bg-white d-flex align-items-center justify-content-center'
              style={{ height: 150, width: 150 }}
            >
              <p className='m-0 fs-3'>
                <span className={completed === total ? '' : 'text-secondary'}>{completed}</span>/{total}
              </p>
            </div>
          </div>

          <div className='w-100'>
            <p>Zone Wise Progress</p>
            <ZoneProgress zoneName='Green' completed={green.completed} total={green.total} />
            <ZoneProgress zoneName='Yellow' completed={yellow.completed} total={yellow.total} />
            <ZoneProgress zoneName='Red' completed={red.completed} total={red.total} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default RecommendationSystem

const ZoneProgress = ({ zoneName, completed, total }) => {
  if (total === 0) return null
  return (
    <>
      <div className='d-flex justify-content-between mb-1'>
        <span className='text-secondary'>{zoneName}</span>
        <span>
          <span className={completed === total ? '' : 'text-secondary'}>{completed}</span>/{total}
        </span>
      </div>

      <ProgressBar
        variant={zoneName === 'Green' ? 'success' : zoneName === 'Yellow' ? 'warning' : 'danger'}
        now={(completed / total) * 100}
        className='mb-2 rounded-pill'
        style={{ height: 3 }}
      />
    </>
  )
}

const data = {
  Memory: [
    {
      name: 'Memory Match',
      objective: 'The goal of this task is to improve memory retention and recall by engaging in a fun and interactive matching exercise.',
      materials: 'Cards with matching pairs (e.g., numbers, letters, pictures, or words).',
      instructions: [
        'Set up the game: Lay all the cards face down on a flat surface (table or floor). The cards should be shuffled beforehand so that no one knows where the matching pairs are.',
        'Flip the cards: Players take turns flipping over two cards at a time. The objective is to find matching pairs (e.g., two cards with the same number, letter, or image).',
        'When a player flips two cards that do not match, they must turn them back over, face down, and try to remember their position for future turns.',
        'Continue flipping until all pairs are found: The game continues as players flip two cards at a time. If a player finds a matching pair, they get to keep those cards and take another turn.',
        'The game ends when all matching pairs have been found.',
      ],
      outcome:
        'This task enhances memory, attention, recall, and concentration by challenging players to remember the location of the cards and find matching pairs.',
      time: '10-15',
      zone: 'Green',
      isCompleted: false,
    },
    {
      name: 'Number Recall',
      objective: 'To enhance memory and concentration by recalling sequences of numbers presented in increasing length.',
      materials: "A list of numbers (the length of the list should be appropriate for the participant's current skill level).",
      instructions: [
        "Step 1: Present the Number Sequence - Start by showing the participant a sequence of numbers. For beginners, start with a short sequence (e.g., 3 or 4 digits) such as '5-2-7-3.' Display or read the numbers aloud clearly.",
        'Step 2: Memorize the Sequence - Give the participant a few seconds to memorize the sequence, depending on its length. Encourage them to focus on the numbers and use any mnemonic techniques they prefer (such as chunking).',
        'Step 3: Recite the Sequence - After they have had time to memorize, ask the participant to recite the sequence from memory.',
        'Step 4: Increase the Sequence Length - As the participant becomes more skilled, gradually increase the length of the sequence. For example, move from 4 digits to 6, 8, or more, depending on their progress.',
      ],
      example: [
        "Start with the sequence: '5-2-7-3.'",
        "After successful recall, increase to: '4-9-1-8-7-6.'",
        'Continue increasing the difficulty as the participant’s recall ability improves.',
      ],
      outcome:
        'This exercise strengthens both memory and concentration by challenging participants to retain and accurately recall number sequences. With regular practice, they will be able to remember longer and more complex sequences, boosting both short-term memory and cognitive focus.',
      time: '10-15',
      zone: 'Yellow',
      isCompleted: false,
    },
    {
      name: 'Story Recall',
      objective:
        'The task aims to develop memory retention and comprehension by encouraging participants to recall and discuss details from a story.',
      materials: 'A short story (can be a printed story, a passage from a book, or even an audio recording).',
      instructions: [
        'Read the Story: Choose a short story that is appropriate for the child’s age or reading level. It should be engaging but not too complex. Read the story aloud to the child, you can assign roles for them to read parts of the story. You may also play an audio recording of the story.',
        "Recall the Story: After reading, ask the participants to recall as many details as they can remember. Encourage them to share what stood out to them, such as key events, character names, settings, or important actions. Give them time to think, and ask open-ended questions like, 'What happened first?' or 'Who was the main character?'",
        'Discuss the Details: After the recall phase, discuss the details the participants remembered. You can list them on a board or piece of paper to keep track. Talk about the accuracy of the details recalled. Were there any important elements that were missed? Did they remember the sequence of events correctly? This discussion encourages them to reflect on how well they comprehended the story.',
      ],
      outcome:
        'This task improves memory retention by encouraging participants to remember details from the story. It also enhances comprehension by reinforcing the ability to focus on and understand key story elements, like plot, characters, and sequence.',
      time: '10-15',
      zone: 'Red',
      isCompleted: false,
    },
  ],
  Association: [
    {
      name: 'Mind Mapping',
      objective:
        'Help the child break down complex topics into smaller, manageable categories to improve understanding, organization, and reasoning skills.',
      materials: ['Paper or notebook', 'Colored pencils/markers', 'Educational apps (optional)', 'Diagrams or graphic organizers (optional)'],
      instructions: [
        "Choose a Broad Topic: Select a general topic the child is currently learning about, such as 'animals,' 'body organs,' or 'favorite things.'",
        "Break it Down: Guide the child to divide the topic into smaller categories. For example, for 'animals,' categorize into mammals, reptiles, birds, etc. For 'body organs,' divide into sense organs (eyes, ears) and internal/external organs (heart, skin). Encourage the child to add more specific examples within each category (e.g., mammals – lions, dogs).",
        "Create a Diagram: Ask the child to draw a diagram or chart that shows the relationships between the categories. Use colors to differentiate categories and make the diagram more visually engaging. For example, a simple tree diagram where 'animals' is the root, and categories like 'mammals,' 'reptiles,' etc., branch off.",
        'Use Apps or Online Tools (optional): Incorporate educational apps or online games that let the child interact with similar concepts through fun activities and interactive visuals.',
        'Reinforce Understanding: Encourage the child to review the diagram and verbally explain each category and its examples. This helps solidify their understanding.',
      ],
      outcome:
        'The child will learn how to break down complex topics into smaller, simpler components. This will improve their ability to organize thoughts, enhance comprehension, and reduce cognitive overload. Creating visual representations of these relationships will strengthen reasoning and memory skills, making learning more engaging and enjoyable.',
      time: '10-15',
      zone: 'Green',
      isCompleted: false,
    },
    {
      name: 'Word Association',
      objective:
        'The goal of this task is to enhance vocabulary and cognitive association by encouraging participants to link words that are related to a specific category.',
      materials: 'None (no materials are needed for this activity).',
      instructions: [
        "Pick a Category: Start by selecting a category or theme that is familiar to the participants. Examples of categories include: Fruits (e.g., apple, banana, orange), Animals (e.g., dog, cat, elephant), Countries (e.g., France, Japan, Italy), Colors (e.g., red, blue, green). The category can be tailored to the participants' age or interests.",
        "Start the Word Chain: Begin the activity by saying a word related to the chosen category. For example, if the category is 'fruits,' the first person might say 'apple.'",
        "Take Turns Adding Words: The next participant must then say a word related to the category. For instance, after 'apple,' they might say 'banana.' Continue taking turns, with each participant saying a word that is related to the category, creating a 'chain' of words.",
        'Encourage Quick Thinking: Encourage participants to think quickly, without pausing for too long. If someone hesitates or cannot think of a word, they are out of the round (optional), or the group can help them.',
        'End the Round: The round continues until no one can think of a new related word. At this point, start a new round with a different category.',
        'Optional Challenge: For more advanced players, increase the difficulty by adding rules. For example, all words must start with the last letter of the previous word, or they must be at least five letters long.',
      ],
      outcome:
        'This task improves verbal fluency by encouraging participants to quickly think of related words within a specific category. It enhances cognitive association skills as they link words through meaning, context, or sound, and expands vocabulary by introducing new words.',
      time: '10-15',
      zone: 'Yellow',
      isCompleted: false,
    },
  ],
  Reasoning: [
    {
      name: 'Logic Puzzles',
      objective: 'Enhance logical reasoning, problem-solving, and deductive thinking through puzzles that challenge the mind.',
      materials: ['Logic puzzles (Sudoku, riddles, or grid-based puzzles)', 'Pencil or marker (optional, depending on the puzzle)'],
      instructions: [
        "Choose a Logic Puzzle: Select a puzzle that suits the participant's skill level, such as Sudoku, riddles, or grid-based logic puzzles (e.g., 'who lives in which house').",
        'Analyze the Puzzle: Carefully read the instructions and clues. Break the puzzle down into smaller parts (e.g., focus on one row or region in Sudoku).',
        'Deduce the Solution: Encourage logical analysis of the clues. Use the process of elimination, if necessary, and write down notes for multi-step puzzles.',
        'Verify the Solution: After completing the puzzle, review the solution to ensure it meets all conditions. If incorrect, reanalyze the clues.',
      ],
      outcome:
        'Participants will develop critical thinking and deductive reasoning skills. These puzzles challenge the brain to think methodically, recognize patterns, and solve problems in an organized manner.',
      time: '20-30',
      zone: 'Green',
      isCompleted: false,
    },
    {
      name: 'Pattern Recognition',
      objective:
        'Improve reasoning skills by identifying and predicting patterns in a sequence, helping participants sharpen their ability to recognize relationships and logical rules.',
      materials: ['Pattern cards (sequences of numbers, shapes, or colors)'],
      instructions: [
        'Observe the Pattern: Show the participant a sequence of patterns on the cards, such as alternating shapes (e.g., circle, square, triangle), colors, or numbers.',
        'Identify the Rule: Ask the participant to determine the rule governing the sequence, considering what changes from one element to the next.',
        'Predict the Next Element: Once the rule is identified, ask the participant to predict the next element in the sequence based on the pattern.',
      ],
      outcome:
        'Participants will develop stronger pattern recognition abilities, improve logical reasoning, and practice predicting outcomes based on observed rules.',
      time: '15-20',
      zone: 'Yellow',
      isCompleted: false,
    },
    {
      name: 'Deduction Game',
      objective: 'Improve reasoning and deduction skills by analyzing clues and forming logical conclusions.',
      materials: ['Clue cards (written clues or cards with hints about a mystery or situation)'],
      instructions: [
        "Step 1: Present a Scenario with Clues: Choose a mystery or puzzle appropriate for the child's age, with clear and basic scenarios for younger children and more challenging clues for older children.",
        'Step 2: Discuss Possible Solutions: Ask the children to analyze the clues and think about what might have happened. Encourage questions and discussions based on the clues.',
        'Step 3: Deduce the Correct Answer: Based on their reasoning, ask the children to deduce the most likely solution. Encourage them to explain how they arrived at their answer.',
      ],
      outcome:
        'This game builds deductive reasoning and critical thinking by requiring participants to analyze clues, discuss solutions, and logically conclude the correct answer. It strengthens their problem-solving abilities.',
      time: '20-25',
      zone: 'Red',
      isCompleted: false,
    },
  ],
  Visualization: [
    {
      name: 'Shape Sorting',
      objective: 'Develop shape recognition and sorting skills, helping children build a foundational understanding of geometry and categorization.',
      materials: ['A variety of shapes (blocks, cutouts, or printed images of basic shapes like squares, circles, triangles, rectangles, etc.).'],
      instructions: [
        'Step 1: Select a Variety of Shapes: Present different shapes to the child, such as a mix of squares, triangles, circles, and rectangles. Use colorful or varied sizes to make it visually engaging.',
        'Step 2: Sort the Shapes Based on Visual Characteristics: Ask the child to sort the shapes into groups based on shape, color, or size.',
        "Step 3: Discuss the Reasoning Behind the Sorting: Ask the child questions like, 'Why did you put these shapes together?' and 'How are these shapes different from the others?'",
      ],
      outcome:
        'This task helps children recognize and categorize different shapes based on their characteristics, building geometry skills while fostering critical thinking.',
      time: '10-15',
      zone: 'Green',
      isCompleted: false,
    },
    {
      name: 'Drawing from Memory',
      objective: 'Enhance the ability to visualize and recall detailed images, improving visual recall and attention to detail.',
      materials: ['An image or object (e.g., a picture of a fruit or a small object like a cup).', 'Paper and pencils.'],
      instructions: [
        'Step 1: Show the Image or Object: Present an image or object, such as a detailed picture of a fruit (e.g., an apple) or a cup. Let the participant observe for 30-60 seconds.',
        'Step 2: Draw from Memory: After removing the image or object, ask the participant to draw it from memory, recalling as many details as possible.',
        'Step 3: Compare and Discuss: Compare the drawing with the original object or image. Discuss which details were captured accurately and which were missed or altered.',
      ],
      outcome:
        "This task improves participants' ability to retain and reproduce specific visual information, strengthening visual memory and attention to detail.",
      time: '10-15',
      zone: 'Yellow',
      isCompleted: false,
    },
    {
      name: 'Visualization of Stories',
      objective: 'Enhance the ability to create mental images based on verbal descriptions, improving comprehension and imagination.',
      materials: ['A short descriptive story or passage (can be from a book or custom-written).'],
      instructions: [
        'Step 1: Read the Story Aloud: Choose a descriptive passage and read it slowly and clearly to allow the listener time to absorb the details.',
        "Step 2: Encourage Mental Visualization: Ask participants to close their eyes and visualize the scene, guiding them with questions like, 'Can you picture the colors of the sunset?'",
        'Step 3: Ask for a Description or Drawing: Ask participants to describe or draw the scene they visualized, focusing on specific details like colors, shapes, and atmosphere.',
      ],
      outcome:
        'Participants develop their ability to transform words into detailed mental images, enhancing comprehension, creativity, and imagination.',
      time: '10-15',
      zone: 'Red',
      isCompleted: false,
    },
  ],
}
