import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Activity from '../dashboard/Activity'
import { NavLink } from 'react-router-dom'
import rocketIcon from '../assets/images/rocket.svg'
import memoryGameIcon from '../assets/images/memory.png'
import arrowKeysIcon from '../assets/images/arrowkeys.png'
import quiz from '../assets/images/quiz.png'
import PayButton from '../pay/PayButton'
import Context from '../Context'
import corsi from '../assets/images/chain.png'
import memoryspan from '../assets/images/creative.png'
import schutletable from '../assets/images/The-Schulte-Table.jpg'
import mentalmaths from '../assets/images/mathematics.png'
import Header from '../profile/Header'

const Activities = () => {
  const { userServices } = useContext(Context)
  const month = 1

  let games = [
    {
      id: 11,
      title: 'Attention & Calm',
      text: 'Boost the rocket with your brain and gain points',
      link: 'rocket',
      images: rocketIcon,
    },
    {
      id: 12,
      title: 'Focus & Calm',
      text: 'Boost the rocket with your brain and gain points',
      link: 'rocket2',
      images: rocketIcon,
    },
    // {
    //   id: 10,
    //   title: 'Match Keys',
    //   text: 'Press keys that appear on the flashcard and gain points',
    //   link: 'leftright',
    //   images: arrowKeysIcon,
    // },
    {
      id: 10,
      title: 'Memory game',
      text: 'Find matching pairs',
      link: 'memorygame',
      images: memoryGameIcon,
      isFree: true,
    },
    // {
    //   id: 10,
    //   title: '15 Puzzle',
    //   text: 'Arrange 1 - 15 numbers in order',
    //   link: 'puzzle',
    //   images: memoryGameIcon,
    // },
    {
      id: 16,
      title: 'Schutle',
      text: 'Focus and find items in order',
      link: 'schutle',
      images: schutletable,
      isFree: false,
    },
    {
      id: 16,
      title: 'Mental Maths',
      text: 'Solve equations quickly',
      link: 'mentalmaths',
      images: mentalmaths,
      isFree: false,
    },
    {
      id: 16,
      title: 'Block Tapping',
      text: 'Tap blocks in correct order',
      link: 'corsi',
      images: corsi,
      isFree: false,
    },
    {
      id: 16,
      title: 'Memory Span',
      text: 'Find the correct sequence of items',
      link: 'memoryspan',
      images: memoryspan,
      isFree: false,
    },
    // {
    //   id: 16,
    //   title: 'License Plate',
    //   text: 'Find the patterns in the license number',
    //   link: 'licenseplate',
    //   images: memoryspan,
    //   isFree: true,
    // },
  ]

  let reports = [
    {
      id: 13,
      title: 'Personality Test',
      text: 'A test to give you an insight on your personality',
      link: 'keirsey',
      reportlink: 'keirsey',
      images: quiz,
    },
    {
      id: 14,
      title: 'Brain Dominance',
      text: 'A test to get insight about you',
      link: 'questionarie',
      reportlink: 'questionarie',
      images: quiz,
    },
    {
      id: 10,
      title: 'Brain Logical',
      text: 'A test for your logical thinking',
      link: 'questionarie?test=logical',
      reportlink: 'questionarielogical',
      images: quiz,
    },
  ]

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='option-main-container'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Games</h2>
            <p>Play games using your brain, literally!</p>
          </div>
          <Activity />
        </div>

        <Header />

        <Row>
          {games.map((d, i) => {
            const isAccessable = d.isFree || userServices.includes(d.id)

            return (
              <Col
                key={i}
                md={6}
                xl={4}
                xxl={3}
                className={`p-2 user-select-none ${isAccessable ? 'order-0' : 'order-1'}`}
              >
                <div className='position-relative'>
                  {!isAccessable && (
                    <div
                      className='d-flex align-items-center justify-content-center'
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255,255,255,0.8)',
                      }}
                    >
                      <PayButton service={d.id} month={month} />
                    </div>
                  )}
                  <div
                    className='dashboard-cards d-flex align-items-center gap-4'
                    style={{ filter: !isAccessable && 'grayscale(100%)' }}
                  >
                    <img src={d.images} alt='icon' height={100} width={100} />

                    <div className='w-100'>
                      <h4 className='m-0 mb-1'>{d.title}</h4>
                      <p>{d.text}</p>

                      <div className='d-flex flex-wrap gap-2'>
                        <div class='btn-container flex-fill'>
                          <NavLink to={`/games/${d.link}`}>
                            <button class='common-btn w-100 p-2'>Play</button>
                          </NavLink>
                        </div>
                        {/* <div class='btn-container flex-fill'>
                          <NavLink to={`/viewreport/games`}>
                            <button class='common-btn w-100 p-2'>View Report</button>
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
        <div className='dashboard-heading mt-4'>
          <div class='content-container'>
            <h2>Questionaries</h2>
            <p>Answer few questions and find the surprise</p>
          </div>
        </div>
        <Row>
          {reports.map((d, i) => {
            const isAccessable = userServices.includes(d.id)
            return (
              <Col
                key={i}
                md={6}
                xl={4}
                xxl={3}
                className={`p-2 user-select-none ${isAccessable ? 'order-0' : 'order-1'}`}
              >
                <div className='position-relative'>
                  {!isAccessable && (
                    <div
                      className='d-flex align-items-center justify-content-center'
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255,255,255,0.8)',
                      }}
                    >
                      <PayButton service={d.id} month={month} />
                    </div>
                  )}
                  <div
                    className='dashboard-cards d-flex align-items-center gap-4'
                    style={{ filter: !isAccessable && 'grayscale(100%)' }}
                  >
                    <img src={d.images} alt='icon' height={100} width={100} />

                    <div className='w-100'>
                      <h4 className='m-0 mb-1'>{d.title}</h4>
                      <p>{d.text}</p>

                      <div className='d-flex flex-wrap gap-2'>
                        <div class='btn-container flex-fill'>
                          <NavLink to={`/${d.link}`}>
                            <button class='common-btn w-100 p-2'>Play </button>
                          </NavLink>
                        </div>
                        <div class='btn-container flex-fill'>
                          <NavLink to={`/viewreport/${d.reportlink}`}>
                            <button class='common-btn w-100 p-2'>View Report</button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Activities

const settings = {
  arrows: true,
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 4000,
  cssEase: 'ease',
}
