import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Chart } from 'react-chartjs-2'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  LineController,
  BarController
)

const SubjectWise = () => {
  const reqJson = headersReq()
  const { selectValue } = useContext(Context)

  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      let isGroup = localStorage.getItem('isGroup') === 'true'
      setLoading(true)
      try {
        const response = await fetch(`${Apiurl.Fetchurl}subject_wise`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            user_id: selectValue.user_id,
            isGroup,
          }),
        })
        const res = await response.json()
        setData(res)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [selectValue.user_id])

  let charts = data && Object.entries(data)

  // let infomation =
  //   data &&
  //   Object.entries(data).map(([key, values]) => {
  //     let firstItem = Object.entries(values)[0]
  //     let firstItemsSorted = Object.entries(firstItem[1])
  //       .sort((a, b) => b[1] - a[1])
  //       .map((obj) => obj[0])
  //     return firstItemsSorted.length > 1
  //       ? `For ${firstItem[0]} in ${key.split('_').join(' ')}, ${firstItemsSorted[0]} is higher than ${
  //           firstItemsSorted[1]
  //         } and others`
  //       : `For ${firstItem[0]} in ${key.split('_').join(' ')}, ${firstItemsSorted[0]} is higher than others`
  //   })

  let role = localStorage.getItem('role')

  return (
    <Row className='mt-5'>
      <div className='page-break' />
      <p className='fs-5' style={{ textAlign: 'justify' }}>
        For example: Your {role === 'parent' || role === 'teacher' ? 'child' : 'individual'} may be more focused on one
        of his favourite topics from a particular subject, which he enjoys the most, as compared to the topics, which he
        does not like at all. These findings highlight all those areas and provide clarity on why your{' '}
        {role === 'parent' || role === 'teacher' ? 'child' : 'individual'} loves learning about a particular subject and
        why he finds other subjects tougher. His innate power to grasp certain topics may be lower and that’s why, his
        brain processes faster on subjects he likes the most.
      </p>
      <p className='fs-5' style={{ textAlign: 'justify' }}>
        Below is a comparison of your strengths in each subject you attemtped, You can click on the legends to show/hide
        the respective subject.
      </p>
      {!loading &&
        charts?.map(([title, data], i) => {
          let chartData = getChartData(data)
          return (
            <Col xxl={12 / (charts.length / 2)} key={i}>
              {i === 2 ? <div className='page-break' /> : null}
              <div className='dashboard-cards stat-container'>
                <div class='performance-heading'>
                  <div class='performance-content'>
                    <h4 className='text-black text-capitalize mb-4'>{title.split('_').join(' ')}</h4>
                  </div>
                </div>

                <Chart type='bar' plugins={[ChartDataLabels]} data={chartData} options={options} height={130} />
              </div>
            </Col>
          )
        })}
    </Row>
  )
}

export default SubjectWise

export const colorsLight = ['#b5d8a6', '#f8c995', '#de8ed2', '#91d3d9']
export const colorsDefault = ['#2D8BBA', '#41B8D5', '#7BC9BF', '#2F5F98', '#96c5dd', '#6d8fb7']
export const colorsDark = ['#558d3d', '#c07622', '#961683', '#1b858f']

export const getChartData = (data) => {
  let main = Object.values(data)
  let arr = {}

  main.map((d) => {
    return Object.entries(d).map(([key, value]) => {
      if (arr[key]) {
        arr[key] = [...arr[key], value]
      } else {
        arr[key] = [value]
      }
    })
  })

  return {
    labels: Object.keys(data).map((t) => t.charAt(0).toUpperCase() + t.slice(1)),
    datasets: Object.entries(arr).map(([label, values], i) => {
      return {
        type: 'bar',
        label: label.charAt(0).toUpperCase() + label.slice(1),
        data: values,
        backgroundColor: colorsDefault[i],
        borderRadius: 8,
        minBarLength: 2,
        maxBarThickness: 50,
      }
    }),
  }
}

export const options = {
  plugins: {
    layout: {
      padding: {
        top: 50,
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 15,
      },
    },
    datalabels: {
      labels: {
        value: {
          anchor: 'end',
          align: 'top',
          display: 'auto',
          color: 'black',
          font: {
            weight: 'bold',
            size: 12,
          },
          offset: 0,
          formatter: (value, ctx) => {
            return Math.ceil(value)
          },
        },
      },
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 25,
      },
    },
  },
}
