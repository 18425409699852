import React, { useContext, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FaRegComment } from 'react-icons/fa'
import { FcLike } from 'react-icons/fc'
import { FiBookmark, FiSend } from 'react-icons/fi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useSpeechRecognition } from 'react-speech-kit'
import * as API from '../../../Apisurl'
import Context from '../../../Context'
import * as Currdate from '../../../CurrDate'

const SocialMedia = ({ submit }) => {
  const [post, setPost] = useState()

  let date = Currdate.GetCurrentDate()

  const upload = async (file) => {
    if (!file) return

    const formData = new FormData()
    formData.append('user_id', sessionStorage.getItem('session'))
    formData.append('folder_type', 'social_media')
    formData.append('file', file)

    const response = await fetch(`${API.Fetchurl}file_upload_1`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('session'),
      },
      body: formData,
    })

    const data = await response.json()
    let imageURL = data.file_path[0]
    return imageURL
  }

  const handleSubmit = async () => {
    if (!post) return

    let imageURL = await upload(post.file)

    // replace image in base64 format with aws-s3 path & delete file key from uploaded post object
    let updatedPost = { ...post, image: imageURL }
    delete updatedPost.file

    submit(date, [updatedPost])
  }

  return (
    <div className='card-container'>
      {post !== undefined ? <Card post={post} /> : <EmptyCard setPost={setPost} />}
      <div className='text-right'>
        <button className='common-btn px-3 py-2' onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}

export default SocialMedia

export const Card = ({ post }) => {
  return (
    <div className='card-ig shadow'>
      <div className='top'>
        <div className='info'>
          <img src={require('../../../assets/images/child2.png')} alt='profile-image' />

          <div className='user-name'>
            <p className='name text-capitalize'>{post.username}</p>
            <p className='id'>@{post.username}</p>
          </div>
        </div>
        <div className='icon'>
          <BsThreeDotsVertical />
        </div>
      </div>

      <div className='body'>
        <img src={post.image} alt='post' />
        <div className='body-icons'>
          <div className='left-icon'>
            <FcLike style={{ fontSize: '22px' }} />
            <FaRegComment className='mx-2' />
            <FiSend />
          </div>
          <div className='right-icon'>
            <FiBookmark />
          </div>
        </div>
      </div>

      <div className='bottom-section'>
        <div className='caption-container'>
          <p className='name text-capitalize'>{post.username}</p>
          <span className='caption'>{post.caption}</span>
        </div>
        <span>{post.postedAt}</span>
      </div>
    </div>
  )
}

export const EmptyCard = ({ setPost }) => {
  const context = useContext(Context)
  const {
    selectValue: { username },
  } = context

  const [caption, setCaption] = useState('')
  const [image, setImage] = useState()
  const [file, setFile] = useState()

  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setCaption(result)
    },
  })

  const handleVoice = () => {
    if (!listening) {
      setCaption('')
      listen()
    } else {
      stop()
    }
  }

  const handleFile = (event) => {
    if (!event.target?.files[0]) return

    setFile(event.target.files[0])

    let reader = new FileReader()
    reader.onload = (e) => setImage(e.target.result)
    reader.readAsDataURL(event.target.files[0])
  }

  const handlePost = () => {
    if (!caption || !image) {
      return
    }

    let currDate = new Date().toLocaleDateString('en-gb', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })

    setPost({ username: username, file: file, image: image, caption: caption, postedAt: currDate })
    setCaption('')
    setImage()
    setFile()
  }

  return (
    <div className='card-ig' style={{ padding: '15px' }}>
      <label htmlFor='caption'>Caption</label>
      <div className='d-flex'>
        <input
          style={{ color: 'black', height: '35px' }}
          className='input-ig mb-2'
          type='text'
          name='caption'
          id='caption'
          placeholder={`${listening ? 'listening' : 'Speak or Type Here'}`}
          onChange={(e) => setCaption(e.target.value)}
          value={caption}
        />
        <svg
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 400 400'
          fill={`${listening ? 'rgb(107, 195, 186)' : 'black'}`}
          onClick={handleVoice}
          style={{ cursor: 'pointer' }}
        >
          <g>
            <path d='M290.991,240.991c0,26.392-21.602,47.999-48.002,47.999h-11.529c-26.4,0-48.002-21.607-48.002-47.999V104.002   c0-26.4,21.602-48.004,48.002-48.004h11.529c26.4,0,48.002,21.604,48.002,48.004V240.991z'></path>
            <path d='M342.381,209.85h-8.961c-4.932,0-8.961,4.034-8.961,8.961v8.008c0,50.26-37.109,91.001-87.361,91.001   c-50.26,0-87.109-40.741-87.109-91.001v-8.008c0-4.927-4.029-8.961-8.961-8.961h-8.961c-4.924,0-8.961,4.034-8.961,8.961v8.008   c0,58.862,40.229,107.625,96.07,116.362v36.966h-34.412c-4.932,0-8.961,4.039-8.961,8.971v17.922c0,4.923,4.029,8.961,8.961,8.961   h104.688c4.926,0,8.961-4.038,8.961-8.961v-17.922c0-4.932-4.035-8.971-8.961-8.971h-34.43v-36.966   c55.889-8.729,96.32-57.5,96.32-116.362v-8.008C351.342,213.884,347.303,209.85,342.381,209.85z'></path>
          </g>
        </svg>
      </div>

      <label>Image</label>
      {image ? (
        <>
          <img src={image} alt='post' style={{ width: '100%', height: '280px', objectFit: 'cover' }} />
          <div>
            <AiOutlineDelete onClick={() => setImage()} style={{ cursor: 'pointer' }} />
          </div>
        </>
      ) : (
        <>
          <label htmlFor='image' className='file-label'>
            +
          </label>
          <input type='file' name='image' id='image' value={image} hidden onChange={handleFile} />
        </>
      )}

      <button
        className='mt-2 border-0 px-4 py-2 rounded w-100 text-white'
        style={{ backgroundColor: 'var(--primary-color)' }}
        type='button'
        onClick={handlePost}
      >
        Post
      </button>
    </div>
  )
}
