import HeaderPdf from './pdfparts/header'

const EmotionalMentalAnalysis = ({ data }) => {
  const listData = [
    {
      percentage: data.Emotional_Analysis.alert[0],
      title: 'Consciousness',
      description: 'Consciousness and alertness entail heightened awareness, being fully present and responsive.',
      trend: `General Trend Observed: ${Math.round(
        data.Emotional_Analysis.alert[2]
      )}% feel conscious while being assessed`,
    },
    {
      percentage: data.Performance_Metric.excitement[0],
      title: 'Excitement',
      description: 'Excitement is an exhilarating state marked by anticipation, enthusiasm, and positivity.',
      trend: `General Trend Observed : ${Math.round(
        data.Performance_Metric.excitement[2]
      )}% is the optimal excitement required for an individual to be happier and be the best self mentally.`,
    },
    // {
    //   percentage: data.Emotional_Analysis.happy[0],
    //   title: 'Happiness',
    //   description: 'Happiness is the profound, subjective experience of joy, contentment, and well-being.',
    //   trend: `General Trend Observed: ${Math.round(data.Emotional_Analysis.happy[2])}% feel happy while gamified EEG scans`,
    // },
    {
      percentage: data.Emotional_Analysis.tired[0],
      title: 'Tiredness',
      description: 'Feeling tired signals physical and mental fatigue, indicating a need for rest.',
      trend: `General Trend Observed : ${Math.round(
        data.Emotional_Analysis.tired[2]
      )}% were tired during the time they got assessed.`,
    },
    {
      percentage: data.Performance_Metric.relax[0],
      title: 'Relaxed',
      description: 'Relaxation is a state of ease, relieving tension, stress, and promoting tranquility.',
      trend: `General Trend Observed: ${Math.round(
        data.Performance_Metric.relax[2]
      )}% relaxation optimizes brain efficiency for complex tasks, promoting productivity.`,
    },

    {
      percentage: data.Performance_Metric.interest[0],
      title: 'Interest',
      description: 'Interest is an engaging cognitive and emotional involvement, fueling curiosity and exploration.',
      trend: `General Trend Observed: To excel academically or align with a career, ${Math.round(
        data.Performance_Metric.interest[2]
      )}% optimal interest is essential.`,
    },
    {
      percentage: data.Performance_Metric.stress[0],
      title: 'Stress',
      description: 'Stress is a response to challenging situations, marked by tension and perceived threat.',
      trend: `General Trend Observed: ${Math.round(
        data.Performance_Metric.stress[2]
      )}% s the red alert; closer means act, farther means continue care`,
    },
  ]

  return (
    <div className='main-container page8-container'>
      <HeaderPdf />
      <div className='pdf-content-container'>
        <div className='heading-seprator'>
          <h1>
            <span>EMOTIONAL ANALYSIS & MENTAL STATE ANALYSIS</span>
          </h1>
          <div className='report-content'>
            <p>Assessment of emotional state gauges feelings during comprehension and questioning.</p>
            <div className='listing-container m-0'>
              <h2 className='m-0 mb-1'>{listData.length || 6} parameters </h2>
              <ul>
                {listData.map((item) => (
                  <li>
                    <h5 style={{ fontFamily: 'sorabold' }}>{Math.round(item.percentage)}%</h5>
                    <div className='list-item'>
                      <h3 style={{ fontFamily: 'sorabold' }}>{item.title}</h3>
                      <p style={{ fontFamily: 'soralight', color: 'black' }}>{item.description}</p>
                      <p className='high-p fw-bold'>{item.trend}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className='p-3 bg-secondary bg-opacity-10' style={{ borderRadius: '16px' }}>
              Elevate happiness, diminish consciousness with NEMA AI's tailored activities.{' '}
              <strong>Invest 20-30 mins, twice weekly.</strong>
            </div>
          </div>
        </div>
        <footer>
          <h6>08</h6>
        </footer>
      </div>
    </div>
  )
}
export default EmotionalMentalAnalysis
