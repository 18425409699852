import React from 'react'
import { Modal, Button, ListGroup } from 'react-bootstrap'

const GameOverModal = ({ show, onHide, validEquations, userEquations, score }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Game Over</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Your Score: {score}</h5>
        <h6>Valid Equations:</h6>
        <ListGroup>
          {validEquations.map((eq, index) => (
            <ListGroup.Item key={index} style={{ color: userEquations.includes(eq) ? 'black' : 'gray' }}>
              {eq}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GameOverModal
