import { MDBIcon } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/button'
import { NavLink, useLocation } from 'react-router-dom'
import swal from 'sweetalert'
import * as Apisurl from '../Apisurl'
import headersReq from '../HeadersJson'
import FormHeader from '../includes/FormHeader'
import RoleformHeader from '../teacher/RoleFormHeading.jsx'
import ChildInfoCard from './Childcard'
import Children from './Children'
import childrenCss from './cssModule/children.module.css'
import FormModal from './FormModal'
import CustomPagination from '../includes/CustomPagination.jsx'

const SelectChild = () => {
  const [addeditVia, setaddeditVia] = useState()
  const [isActive1, setActive] = useState(0)

  const [search, setSearch] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 12 // Number of items per page

  let reqJson = headersReq()

  const userinitialValues = {
    username: '',
    // age: '',
    class: '',
    special_need: '',
    parent_name: '',
    school_name: '',
    gender: '',
    dob: '',
    person_type: 'child',
    image: '',
  }

  const [uservalues, setInputValue] = useState(userinitialValues)
  const [userid, setuserId] = useState()

  const role = localStorage.getItem('role')

  const handleClose = () => setShow(false)

  const handleShow = (via) => {
    console.log(via)

    setaddeditVia(via)
    setInputValue({
      username: '',
      // age: '',
      class: '',
      special_need: '',
      parent_name: '',
      school_name: '',
      gender: '',
      dob: '',
      person_type: 'child',
      image: '',
    })
    setShow(true)
  }

  let loc = useLocation()

  let pageid
  if (loc.state != null) {
    console.log(loc.state)
    pageid = loc.state.pageid
  }
  console.log(pageid)
  const [show, setShow] = useState(false)
  const [datai, setDatai] = useState([])

  useEffect(() => {
    populateChild1()
  }, [])

  const populateChild1 = () => {
    if (sessionStorage.getItem('session') === '123456') {
      setDatai(JSON.parse(localStorage.getItem('childData'))?.map((child, i) => ({ ...child, row: i })) || [])
      return
    }

    let uservaluesdata = JSON.stringify({
      person_uid: window.sessionStorage.getItem('session'),
    })

    async function populateChild() {
      const response = await fetch(`${Apisurl.Fetchurl + 'child'}`, {
        method: 'POST',
        headers: reqJson,
        body: uservaluesdata,
      })
      const childdata = await response.json()
      return childdata
    }

    populateChild()
      .then((data) => {
        // setActive(false);
        let modifiedData = data.Status.children.registred_child.map((child, i) => ({ ...child, row: i }))
        setDatai(modifiedData)
        console.log(datai)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editChild = (e) => {
    if (sessionStorage.getItem('session') === '123456') {
      let childdata = JSON.parse(localStorage.getItem('childData'))[0]
      handleShow(1)
      setInputValue({
        username: childdata.username,
        dob: childdata.dob,
        gender: childdata.gender,
        class: childdata.class,
        school_name: childdata.school_name,
        parent_name: childdata.parent_name,
        special_need: childdata.special_need,
      })
      setuserId(childdata.user_uid)

      return
    }

    async function editChild12() {
      const response = await fetch(
        Apisurl.Fetchurl + `edit_child?userid=${e}&person_uid=${window.sessionStorage.getItem('session')}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const data = await response.json()
      return data
    }

    editChild12()
      .then((data) => {
        let childdata = data
        console.log(childdata, '123123')
        handleShow(1)
        setInputValue({
          username: childdata.username,
          // age: childdata.age,
          dob: childdata.dob,
          gender: childdata.gender,
          class: childdata.class,
          school_name: childdata.school_name,
          parent_name: childdata.parent_name,
          special_need: childdata.special_need,
        })
        setuserId(childdata.user_uid)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteChild = (e) => {
    if (sessionStorage.getItem('session') === '123456') {
      localStorage.removeItem('childData')
      return setDatai([])
    }
    let uid = e
    if (window.confirm('Are you sure want to remove this child')) {
      async function deleteChild12(e) {
        const response = await fetch(Apisurl.Fetchurl + `delete_child`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            userid: uid,
            person_uid: window.sessionStorage.getItem('session'),
          }),
        })
        const removeSuccess = await response.json()
        return removeSuccess
      }
      deleteChild12()
        .then((data) => {
          if (data.status == 400) {
            alert(data.error)
          } else {
            swal('Good job!', 'You deleted this child user successfully!', 'success').then(() => {
              populateChild1()
              setActive((p) => p - 1)
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const children =
    !search || search.length < 2
      ? datai
      : datai.filter(
          (d) =>
            d.username.toLowerCase().includes(search.toLowerCase()) ||
            d.school_name.toLowerCase().includes(search.toLowerCase())
        )

  const handleSearchQueryChange = (e) => {
    setSearch(e.target.value)
    setCurrentPage(1)
  }

  return (
    <>
      <FormHeader />
      <div id='google_translate_element'></div>
      <Container style={{ minHeight: '81.5vh' }}>
        <RoleformHeader role={role === 'hr' || role === 'employee' ? 'Add User' : 'Add Child'} />
        <Row className='align-items-end justify-content-between py-3'>
          <Col md={4}>
            <input type='text' placeholder='Search' value={search} onChange={handleSearchQueryChange} />
          </Col>
          <Col md={4}>
            <button className={`${childrenCss.addBtn} mt-0`} onClick={() => handleShow(2)}>
              + Add Another {role === 'hr' || role === 'employee' ? 'User' : 'Child'}
            </button>
          </Col>
        </Row>
        {datai.length === 0 ? (
          <Children handleShow={() => handleShow()} />
        ) : (
          <Row className='my-3'>
            {children?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((o) => {
              return (
                <ChildInfoCard
                  key={o.row}
                  index={o.row}
                  childdata={o}
                  openFn={() => handleShow(2)}
                  deleteChild={() => deleteChild(o.user_uid)}
                  editChild={() => editChild(o.user_uid)}
                  sendbtnvia='false'
                  setActive={setActive}
                  isActive={isActive1}
                  classN='active'
                />
              )
            })}
          </Row>
        )}
        <CustomPagination
          totalItems={children.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Container>
      <div className='position-sticky w-100 px-4 py-3 pt-4 bottom-0 start-0' style={{ backgroundColor: '#fbfcfc' }}>
        <Container>
          <Row>
            <Col md={4} className={`${childrenCss.btnContainer}`}>
              <div className={childrenCss.redirectoption}>
                <NavLink
                  className='w-100'
                  onClick={() => localStorage.setItem('ischildActive', isActive1)}
                  to={
                    pageid === 1
                      ? '/'
                      : pageid === 2
                      ? '/profile'
                      : pageid === 3
                      ? '/overview'
                      : '/children/add/success'
                  }
                >
                  <Button variant='primary' className='common-btn full-btn w-100' type='button'>
                    Continue
                  </Button>
                </NavLink>
                <p className='active' style={{ marginLeft: 20 }}>
                  Skip
                </p>
              </div>
              <p>
                <MDBIcon fas icon='exclamation-circle' />
                Don’t worry, you can always add more {role === 'hr' || role === 'employee' ? 'users' : 'children'} later
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {show && (
        <FormModal
          totalChildren={datai.length}
          sendModalVia={show}
          open={handleShow}
          setshow={setShow}
          close={handleClose}
          uservalues={uservalues}
          editvia={addeditVia}
          userid={userid}
          populateChild={() => populateChild1()}
          setInputValue={setInputValue}
        />
      )}
    </>
  )
}

export default SelectChild
