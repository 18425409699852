import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

const ActivityDetails = () => {
  const location = useLocation()

  let activity = null
  if (location.state) {
    activity = location.state.activity
  }

  return (
    <div className=''>
      <div className='px-4 py-3 border-3 border-bottom d-flex justify-content-between align-items-center'>
        <h6 className='opacity-100 text-black m-0'>
          {activity.zone === 'Green' ? '🟢' : activity.zone === 'Yellow' ? '🟡' : '🔴'}
          <span className='ms-2'>{activity.name}</span>
        </h6>
        {activity.time} mins
      </div>

      <Row className='m-0'>
        <Col className='p-0'>
          <Row className='m-0'>
            <Col xl={6} className='p-3 border'>
              <h6 className='m-0 mb-2 opacity-100 text-primary'>1.Objective</h6>
              <p style={{ lineHeight: '1.5' }}>{activity.objective}</p>
            </Col>
            <Col xl={6} className='p-3 border'>
              <h6 className='m-0 mb-2 opacity-100 text-success'>2.Outcome</h6>
              <p style={{ lineHeight: '1.5' }}>{activity.outcome}</p>
            </Col>
          </Row>
          <Row className='m-0 h-100'>
            <Col className='p-3 border '>
              <h6 className='m-0 mb-2 opacity-100 text-warning'>3.Materials</h6>
              <p style={{ lineHeight: '1.5' }}>{activity.materials}</p>
            </Col>
          </Row>
        </Col>
        <Col xl={6} className='p-3 border'>
          <h6 className='m-0 mb-2 opacity-100 text-danger'>4.Instructions</h6>
          <ul>
            {activity.instructions.map((step, index) => {
              return (
                <li key={index} className='mb-3' style={{ lineHeight: '1.5' }}>
                  {step}
                </li>
              )
            })}
          </ul>
        </Col>
      </Row>
    </div>
  )
}

export default ActivityDetails
